export default function getNewsCardsData() {
  return [
    {
      Date: '5/1/1994',
      Headline: 'Dadong Wan completes Ph.D. thesis',
      Text: '"CLARE: A Computer-Supported Collaborative Learning Environment Based on the Thematic Structure of Scientific Text"',
      URL: 'http://csdl.ics.hawaii.edu/research/clare',
      Media: 'images/clare-small.png',
    },
    {
      Date: '6/1/1995',
      Headline: 'Cam Moore completes M.S. thesis',
      Text: '"Supporting authoring and learning in a strongly collaborative hypertext system: The Annotated Egret Navigator"',
      URL: 'http://csdl.ics.hawaii.edu/research/aen',
      Media: 'images/aen.png',
    },
    {
      Date: '7/1/1995',
      Headline: 'Rosemary Andrada Sumajit completes M.S. thesis',
      Text: '"Building Community through the World Wide Web"',
      URL: '',
      Media: 'images/www.png',
    },
    {
      Date: '8/1/1996',
      Headline: 'Danu Tjahjono completes Ph.D. thesis',
      Text: '"Exploring the effectiveness of formal technical review factors with CSRS, a collaborative software review system"',
      URL: 'http://csdl.ics.hawaii.edu/research/csrs',
      Media: 'images/csrs.gif',
    },
    {
      Date: '5/1/1998',
      Headline: 'Jennifer Geis completes M.S. research',
      Text: '"JavaWizard: Investigating Defect Detection and Analysis"',
      URL: 'http://csdl.ics.hawaii.edu/research/jwiz',
      Media: 'images/jwiz.jpg',
    },
    {
      Date: '5/1/1999',
      Headline: 'Anne Disney completes M.S. thesis',
      Text: '"Data Quality Problems in the Personal Software Process"',
      URL: 'http://csdl.ics.hawaii.edu/research/psp-data-quality',
      Media: 'images/psp-data-quality-small.png',
    },
    {
      Date: '12/1/1999',
      Headline: 'Joe Dane completes M.S. research',
      Text: '"Modular Program Size Counting"',
      URL: 'http://csdl.ics.hawaii.edu/research/locc',
      Media: 'images/locc.gif',
    },
    {
      Date: '3/1/2000',
      Headline: 'Robert Brewer completes M.S. thesis',
      Text: '"Improving Mailing List Archives Through Condensation"',
      URL: 'http://csdl.ics.hawaii.edu/research/mcs',
      Media: 'images/mcs-small.png',
    },
    {
      Date: '8/1/2000',
      Headline: 'Cam Moore completes Ph.D. thesis',
      Text: '"Investigating Individual Software Development: An Evaluation of the Leap Toolkit"',
      URL: 'http://csdl.ics.hawaii.edu/research/leap',
      Media: 'images/leap.gif',
    },
    {
      Date: '8/1/2000',
      Headline: 'Monir Hodges completes M.S. thesis',
      Text: '"JavaJam: Supporting Collaborative Review and Improvement of Open Source Software"',
      URL: 'http://csdl.ics.hawaii.edu/research/javajam',
      Media: 'images/javaJAM.gif',
    },
    {
      Date: '5/1/2001',
      Headline: 'Timothy Burgess completes M.S. thesis',
      Text: '"An artificial neural network for recognition of simulated dolphin whistles"',
      URL: '',
      Media: 'images/dolphin.png',
    },
    {
      Date: '6/1/2001',
      Headline: 'Mette Moffett completes undergraduate honors thesis',
      Text: '"The Design, Development, and Evaluation of VCommerce: A Virtual Environment to Support Entrepreneurial Learning"',
      URL: 'http://csdl.ics.hawaii.edu/research/vcommerce',
      Media: 'images/VCommerce.jpg',
    },
    {
      Date: '5/1/2002',
      Headline: 'Jitender Miglani completes M.S. thesis',
      Text: '"The design, implementation, and evaluation of INCA: an automated system for approval code allocation"',
      URL: 'http://csdl.ics.hawaii.edu/research/inca',
      Media: 'images/inca-small.png',
    },
    {
      Date: '5/1/2003',
      Headline: 'Joy Agustin completes M.S. thesis',
      Text: '"Improving software quality through extreme coverage with JBlanket"',
      URL: 'http://csdl.ics.hawaii.edu/research/jblanket',
      Media: 'images/JBlanket.gif',
    },
    {
      Date: '5/1/2003',
      Headline: 'Hackystat',
      Text: 'Support for fine-grained software development process and product data collection and analysis.',
      URL: 'http://csdl.ics.hawaii.edu/research/hackystat',
      Media: 'images/hackystat.png',
    },
    {
      Date: '5/1/2003',
      Headline: 'Aaron Kagawa completes undergraduate honors thesis',
      Text: '"The design, implementation, and evaluation of CLEW: An improved Collegiate Department Website"',
      URL: '',
      Media: 'images/kagawa.jpeg',
    },
    {
      Date: '5/1/2005',
      Headline: 'SCLC',
      Text: 'Multi-language code counting.',
      URL: 'http://csdl.ics.hawaii.edu/research/sclc',
      Media: 'images/sclc.gif',
    },
    {
      Date: '8/1/2005',
      Headline: 'Aaron Kagawa completes M.S. thesis',
      Text: '"Priority Ranked Inspection: Supporting Effective Inspection in Resource-limited Organizations"',
      URL: 'http://csdl.ics.hawaii.edu/research/priority-ranked-inspection',
      Media: 'images/kagawa.jpeg',
    },
    {
      Date: '12/1/2006',
      Headline: 'Qin Zhang completes Ph.D. thesis',
      Text: '"Improving Software Development Process and Product Management with Software Project Telemetry"',
      URL: 'http://csdl.ics.hawaii.edu/research/software-project-telemetry',
      Media: 'images/telemetry.png',
    },
    {
      Date: '12/1/2007',
      Headline: 'Hongbing Kou completes Ph.D. thesis',
      Text: '"Automated Inference of Software Development Behaviors: Design, Implementation and Validation of Zorro for Test-Driven Development"',
      URL: 'http://csdl.ics.hawaii.edu/research/zorro',
      Media: 'images/zorro.png',
    },
    {
      Date: '5/1/2008',
      Headline: 'Takuya Yamashita completes M.S. thesis',
      Text: '"Evaluation of Jupiter: A Lightweight Code Review Framework"',
      URL: 'http://csdl.ics.hawaii.edu/research/jupiter',
      Media: 'images/Jupiter.jpg',
    },
    {
      Date: '5/1/2009',
      Headline: 'Shaoxuan Zhang completes M.S. thesis',
      Text: '"Learning Empirical Software Engineering Using Software Intensive Care Unit"',
      URL: 'http://csdl.ics.hawaii.edu/research/software-icu',
      Media: 'images/softwareicu.gif',
    },
    {
      Date: '6/1/2009',
      Headline: 'Google Summer of Code',
      Text: 'For the second year in a row, the Hackystat project was selected to participate in Google Summer of Code.',
      URL: 'http://csdl.ics.hawaii.edu/education/google-summer-of-code',
      Media: 'images/google-summer-of-code-logo.png',
    },
    {
      Date: '7/1/2009',
      Headline: 'WattDepot',
      Text: 'WattDepot supports development of enterprise level energy data collection and analysis.',
      URL: 'http://csdl.ics.hawaii.edu/research/wattdepot',
      Media: 'images/WattDepot.png',
    },
    {
      Date: '10/1/2011',
      Headline: 'Inaugural Kukui Cup',
      Text: 'After two years of preparation, the first Kukui Cup Energy Challenge begins.',
      URL: 'http://csdl.ics.hawaii.edu/research/kukui-cup',
      Media: 'images/kukuicup-logo.jpg',
    },
    {
      Date: '6/1/2012',
      Headline: 'George Lee completes M.S. thesis',
      Text: '"Makahiki: An extensible open-source platform for creating energy competitions"',
      URL: 'http://csdl.ics.hawaii.edu/research/makahiki',
      Media: 'images/makahiki-home-page.png',
    },
    {
      Date: '9/1/2012',
      Headline: '2012 Kukui Cup Challenges at UH, HPU, EWC',
      Text: 'The Kukui Cup expands to three campuses in 2012.',
      URL: 'http://csdl.ics.hawaii.edu/research/kukui-cup',
      Media: 'images/kc12-logo.png',
    },
    {
      Date: '5/1/2013',
      Headline: 'Robert Brewer completes Ph.D. thesis',
      Text: '"Fostering Sustained Energy Behavior Change And Increasing Energy Literacy In A Student Housing Energy Challenge"',
      URL: 'http://csdl.ics.hawaii.edu/research/kukui-cup',
      Media: 'images/kukuicup-logo.jpg',
    },
    {
      Date: '10/28/2013',
      Headline: 'Robert Brewer wins sustainability award',
      Text: 'Robert Brewer (Ph.D., Computer Science, 2013) has won the 2013 AASHE Graduate Student Research on Campus Sustainability Award.',
      URL: 'http://manoa.hawaii.edu/news/article.php?aId=6085',
      Media: 'images/img6085_3545.jpg',
    },
    {
      Date: '4/24/2014',
      Headline: 'Jordan Takayama completes undergraduate honors thesis',
      Text: '"Makahiki system administration usability"',
      URL: '',
      Media: 'images/jordan-takayama.jpg',
    },
    {
      Date: '1/16/2015',
      Headline: 'Pavel Senin completes Ph.D. thesis',
      Text: '"Software Trajectory Analysis: An empirically based method for automated software process discovery"',
      URL: 'http://csdl.ics.hawaii.edu/research/software-trajectory-analysis/',
      Media: 'images/pavel-senin.jpg',
    },
    {
      Date: '7/16/2015',
      Headline: 'Yongwen Xu completes Ph.D. thesis',
      Text: '"Yongwen Xu completes Ph.D. research: "Makahiki and SGSEAM: Design and Evaluation of A Serious Game Framework for Sustainability and Stakeholder Experience Assessment Method"',
      URL: 'http://csdl.ics.hawaii.edu/research/makahiki/',
      Media: 'images/yongwen-larger.jpg',
    },
    {
      Date: '3/3/2016',
      Headline: 'OPQ students win at Ideas Festival',
      Text: 'OPQ undergraduate researchers Rochelle Pagaduan and David Badke take second place in the student division at the Booz Allen Ideas Festival 2016',
      URL: 'http://www.boozallen.com/lp/booz-allen-ideas-festival-2016',
      Media: 'images/booz-allen-ideafest.png',
    },
    {
      Date: '8/1/2016',
      Headline: 'Greg Burgess completes M.S. thesis',
      Text: 'MANDE (the Maximal Acoustic Network Designer) takes advantage of high-resolution bathymetric data and advanced animal modeling to provide optimal network designs.',
      URL: 'http://csdl.ics.hawaii.edu/techreports/2016/16-04/16-04.pdf',
      Media: 'images/burgess.jpg',
    },
    {
      Date: '12/1/2016',
      Headline: 'New OPQ Box Design Released',
      Text: 'The Open Power Quality project has finished assembling engineering samples of its latest hardware design.',
      URL: 'http://openpowerquality.org/organization/news.html',
      Media: 'images/opqbox-feb-2019-square-closed.jpg',
    },
    {
      Date: '7/7/2017',
      Headline: 'Amy Takayesu completes M.S. thesis',
      Text: '"RADGRAD: Using degree planning, social networking, and gamification to improve academic, professional, and social engagement during the undergraduate computer science degree experience".',
      URL: 'https://www.radgrad.org/blog/2017/07/07/amy-takayesu.html',
      Media: 'images/amy.jpeg',
    },
    {
      Date: '2/8/2018',
      Headline: 'OPQ wins Presidents Green Award',
      Text: 'Open Power Quality wins a $10,000 prize for proposed work on agile power monitoring to support UH net zero goals.',
      URL: 'http://www.hawaii.edu/news/2018/02/12/2018-presidents-green-awards/',
      Media: 'images/green-awards-group.jpg',
    },
    {
      Date: '4/12/2018',
      Headline: 'Sergey Negrashov wins ARCS fellowship',
      Text: 'Serge Negrashov receives the 2018 Martin Award for Information and Computer Science from the ARCS Foundation.',
      URL: 'http://www.ics.hawaii.edu/2018/04/sergey-negrashov-receives-arcs-fellowship/',
      Media: 'images/arcs-foundation-serge.jpg',
    },
    {
      Date: '9/1/2018',
      Headline: 'NSF funds RadGrad',
      Text: 'NSF awards $300,000 to assess the impact of RadGrad on engagement, retention, and diversity.',
      URL: 'https://www.hawaii.edu/news/2018/09/04/computer-science-retention-grant/',
      Media: 'images/radgrad_logo.png',
    },
    {
      Date: '5/1/2019',
      Headline: 'Philip Johnson receives teaching award',
      Text: 'Philip Johnson received the 2019 Board of Regents Medal for Excellence in Teaching.',
      URL: 'https://www.hawaii.edu/news/2019/05/13/bor-medal-for-excellence-2019/',
      Media: 'images/bor-medal.jpg',
    },
    {
      Date: '2/24/2020',
      Headline: 'Anthony Christe completes Ph.D. dissertation',
      Text: '"LAHA: A framework for adaptive optimization of distributed sensor frameworks."',
      URL: 'https://openpowerquality.org/blog/2020/02/24/anthony-dissertation.html',
      Media: 'images/christe.png',
    },
    {
      Date: '3/10/2020',
      Headline: 'Sergey Negrashov completes Ph.D. dissertation',
      Text: '"Design, Implementation, and Evaluation of Napali: A novel distributed sensor network for improved power quality monitoring."',
      URL: 'https://openpowerquality.org/blog/2020/03/10/serge-dissertation.html',
      Media: 'images/serge.jpg',
    },
    {
      Date: '6/1/2020',
      Headline: 'Sergey Negrashov and Anthony Christe organize AlohaMask',
      Text: 'In response to COVID-19, Sergey and Anthony organized a volunteer network of over 600 people to mass produce PPEs.',
      URL: 'https://alohamask.org/',
      Media: 'images/alohamask.svg',
    },
    {
      Date: '7/31/2021',
      Headline: 'Undergrads complete Summer 2021 NSF REU program',
      Text: 'Five undergraduates completed their summer research experience and presented their results at the UH SURE Symposium',
      URL: 'https://www.radgrad.org/blog/2021/08/01/summer-reu',
      Media: 'images/nsf-logo.jpg',
    },
    {
      Date: '8/1/2021',
      Headline: 'Morea Framework Version 3 released',
      Text: 'Philip finished updating the Morea Framework and released Version 3 with bug fixes and new features.',
      URL: 'https://morea-framework.github.io/blog/2021/08/01/morea-version-3',
      Media: 'images/morea-module.png',
    },
    {
      Date: '12/9/2021',
      Headline: 'InternAloha Version 2 Development',
      Text: 'Three undergraduates helped design and implement a new major release of InternAloha as part of their capstone experience.',
      URL: 'https://www.radgrad.org/blog/2021/12/09/internaloha',
      Media: 'images/internaloha-small.png',
    },
    {
      Date: '5/18/2022',
      Headline: 'Johnson and Moore participate in EPSCOR award',
      Text: 'Philip Johnson is a co-PI, and Cam Moore is a senior personnel on the NSF-sponsored "Change Hawaii: Harnessing the Data Revolution for Island Resilience" project.',
      URL: 'https://www.hawaii.edu/news/2022/05/16/20m-tclimate-resilience-through-data-science/',
      Media: 'images/nsf-logo.jpg',
    },
    {
      Date: '9/18/2023',
      Headline: 'Johnson participates in NRT award',
      Text: 'Philip Johnson is a co-PI on the NSF-sponsored "Data in Engineering and Society: Converging Applications, Research, and Training Enhancements for Students" project.',
      URL: 'https://new.nsf.gov/funding/initiatives/nrt/nsf-invests-63-million-stem-research-traineeships',
      Media: 'images/nsf-logo.jpg',
    },
  ].reverse();
}
